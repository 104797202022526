import React from 'react'

import { Text, Button, Flex } from 'components'

export const TestPage = () => {
  return (
    <Flex grow col>
      <Flex
        p={8}
        col
        vAlignContent="space-around"
        grow
        hAlignContent="center"
        minHeight={600}
      >
        <Text.p textDecoration="underline" textTransform="uppercase">
          test123
        </Text.p>
        <Button rounded size="small" fontWeight="bold" bg="blue">
          small2
        </Button>
        <Button>medium</Button>
        <Button fontWeight="bold" color="brand.primary">
          large2
        </Button>

        {/* <Button.large>large</Button.large> */}
        {/* <Button size="small">Test123</Button>
        <Button size="large">Test123</Button> */}
      </Flex>
      <Flex height={800}> asd</Flex>
    </Flex>
  )
}

export default TestPage
